import React from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Snackbar from './components/ListSnackbar';
import Header from './components/Header';
import ListUpdateView from './views/ListUpdateView';
import AuthorsView from './views/AuthorsView';
import AuthorUpdateView from './views/AuthorUpdateView';
import ItemsView from './views/ItemsView';
import ItemUpdateView from './views/ItemUpdateView';
import SettingsView from './views/SettingsView';
import ItemReadView from './views/ItemReadView';
import TestView from './views/TestView';
import ListsView from './views/ListsView';

function getTheme(darkState) {
  const options = {
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiChip: {
        defaultProps: {
          variant: 'outlined',
        },
      },
    },
  };

  const lightPalette = {
    mode: 'light',
    primary: {
      main: '#F55139',
      contrastText: '#000000',
    },
    secondary: {
      main: '#3C3C3C',
    },
  };

  const darkPalette = {
    mode: 'dark',
    primary: {
      main: '#f55139',
    },
    secondary: {
      main: '#F55139',
    },
  };

  options.palette = darkState ? darkPalette : lightPalette;
  return createTheme(options);
}

function App() {
  const darkMode = useSelector((state) => state.users.darkMode);
  const theme = getTheme(darkMode);

  const isTest = window.location && window.location.pathname === '/test';
  if (isTest) {
    return (
      <TestView />
    );
  }

  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snackbar />
          <Header />
          <Routes>
            <Route element={<ItemReadView />} path="/items/:itemId" exact />
            <Route element={<Navigate to="/explore/items" replace />} path="/" exact />
            <Route element={<Navigate to="/explore/items" replace />} path="/explore" exact />
            <Route element={<ItemsView namespace="public" />} path="/explore/:listId" exact />
            <Route element={<ListsView namespace="public" />} path="/explore/lists" exact />
            <Route element={<ItemsView namespace="public" />} path="/explore/items" exact />
            <Route element={<ItemsView namespace="public" type="authorItems" />} path="/explore/author/:authorId" exact />
            <Route element={<ItemsView namespace="public" />} path="/explore/items" exact />
            <Route element={<AuthorsView namespace="public" />} path="/explore/authors" exact />
            <Route element={<ListsView />} path="/learn/lists" exact />
            <Route element={<ListsView namespace="pending" />} path="/curate/lists" exact />
            <Route element={<ItemsView />} path="/learn" exact />
            <Route element={<ItemsView />} path="/learn/items" exact />
            <Route element={<ItemsView namespace="pending" />} path="/curate/items" />
            <Route element={<ItemsView />} path="/learn/:listId" exact />
            <Route element={<ItemsView type="authorItems" />} path="/learn/author/:authorId" exact />
            <Route element={<ItemsView namespace="pending" type="authorItems" />} path="/curate/author/:authorId" exact />
            <Route element={<ItemsView view="share" />} path="/share" />
            <Route element={<AuthorsView />} path="/learn/authors" exact />
            <Route element={<AuthorsView namespace="pending" />} path="/curate/authors" exact />
            <Route element={<AuthorUpdateView />} path="/edit/author/:authorId" exact />
            <Route element={<AuthorUpdateView />} path="/create/author" />
            <Route element={<ItemUpdateView />} path="/edit/item/:itemId" />
            <Route element={<ItemUpdateView />} path="/create/item" />
            <Route element={<ItemUpdateView />} path="/create/item/:listId" />
            <Route element={<ListUpdateView />} path="/edit/list/:listId" />
            <Route element={<ListUpdateView />} path="/create/list" />
            <Route element={<SettingsView />} path="/settings" />
          </Routes>
        </ThemeProvider>
      </DndProvider>
    </BrowserRouter>
  );
}

export default App;
