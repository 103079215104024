import { CircularProgress, Toolbar } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { mergePublicAndPrivateItems } from '../common/ItemTools';
import ItemCard from '../components/ItemCard';
import { deleteItem, retrieveItem, selectContext } from '../redux/features/curatedSlice';
import StyledBackdrop from '../components/StyledBackdrop';
import { selectUserProfile } from '../redux/features/usersSlice';
import Login from '../components/Login';

const CardFrame = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2%',
});

const CardBox = styled(Box)({
  minWidth: '60%',
  maxWidth: '60%',
});

export default function ItemView(props) {
  const { type: propType } = props;
  const type = propType || 'item';
  const { itemId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userProfile = useSelector(selectUserProfile);
  const status = useSelector((state) => state.curated.contextStatus);
  const currContext = useSelector(selectContext);

  let context = null;
  if (currContext && currContext.type === type) {
    context = currContext;
  }

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    dispatch(retrieveItem({ itemId }));
  }, [itemId, userProfile]);

  let combinedItem;
  let forceSignIn = false;
  if (status !== 'loading' && context) {
    if (context.item) {
      combinedItem = mergePublicAndPrivateItems(context.item);
    } else {
      forceSignIn = true;
    }
  }

  const onItemDelete = (item) => {
    dispatch(deleteItem({ item }));
    navigate(-1);
  };
  const onItemEdit = (editItem) => navigate(`/edit/item/${editItem.id}`);
  const onItemOpen = (newItem) => window.open(newItem.sourceUrl, '_blank');
  const onTagOpen = (tag) => navigate(`/explore/${tag}`);
  const onAuthorView = (aid) => { navigate(`/learn/author/${aid}`); };
  const itemActions = {
    onItemEdit, onItemRead: onItemOpen, onItemOpen, onTagOpen, onAuthorView,
  };

  if (combinedItem && combinedItem.hasPrivateData) {
    itemActions.onItemDelete = onItemDelete;
  }

  return (
    <>
      <Toolbar />
      { forceSignIn && <Login />}
      { !forceSignIn && (
        <StyledBackdrop open={!combinedItem}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
      { !forceSignIn && combinedItem && (
        <CardFrame>
          <CardBox>
            <ItemCard
              item={combinedItem}
              isPreview
              {...itemActions}
            />
          </CardBox>
        </CardFrame>
      )}
    </>
  );
}
